<template>
  <b-card
    v-if="data"
    no-body
    class="p-1 full-height"
  >
    <b-card-title class="mb-1">
      {{ data.title }}
    </b-card-title>

    <!-- apex chart -->
    <vue-apex-charts
      type="radialBar"
      height="245"
      class="my-2"
      :options="goalOverviewRadialBar()"
      :series="data.values"
    />
    <b-card-text
      v-if="data.footer"
      class="text-center"
    >
      <div
        v-if="data.percent > 0"
        class="text-success"
      >
        <i class="fa-solid fa-chevron-up" /> <span class="font-weight-bolder">{{ data.percent }}%</span>
      </div>
      <div
        v-else-if="data.percent < 0"
        class="text-danger"
      >
        <i class="fa-solid fa-chevron-down" /> <span class="font-weight-bolder">{{ data.percent }}%</span>
      </div>
      <div
        v-else
        class="text-info"
      >
        <i class="fa-solid fa-equals" /> <span class="font-weight-bolder">{{ data.percent }}%</span>
      </div>
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BCardTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

const $strokeColor = '#ebe9f1'
const $textHeadingColor = '#8692D0'

export default {
  name: 'RadialChart',
  components: {
    BCardTitle,
    VueApexCharts,
    BCard,
    BCardText,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // Define goalOverviewRadialBar as a function that returns a new object
      goalOverviewRadialBar: () => ({
        chart: {
          height: 245,
          type: 'radialBar',
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1,
          },
        },
        colors: [this.$props.data.color],
        plotOptions: {
          radialBar: {
            offsetY: -10,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: '77%',
            },
            track: {
              background: $strokeColor,
              strokeWidth: '100%',
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                color: $textHeadingColor,
                fontSize: '2.86rem',
                fontWeight: '600',
              },
            },
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: [this.$props.data.color],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: 'round',
        },
        grid: {
          padding: {
            bottom: 30,
          },
        },
      }),
    }
  },
}

</script>
