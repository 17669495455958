<template>
  <b-card
    v-if="data"
    no-body
    class="card-revenue-budget full-height"
  >
    <b-row class="mx-0 full-height">
      <b-col
        md="12"
        class="revenue-report-wrapper align-self-start border-right full-height"
      >
        <div class="d-sm-flex justify-content-between align-items-center mb-2">
          <h4 class="card-title mb-50 mb-sm-0">
            {{ data.title }}
          </h4>
          <div class="d-flex align-items-center">
            <div class="d-flex align-items-center mr-2">
              <img
                src="../../../assets/images/svg/verified.svg"
                class="img-fluid"
                alt="verified"
              >&nbsp;&nbsp;
              <span class="">{{ data.positive }}</span>
            </div>
            <div class="d-flex align-items-center">
              <img
                src="../../../assets/images/svg/rejected.svg"
                class="img-fluid"
                alt="rejected"
              >&nbsp;&nbsp;
              <span class="">{{ data.negative }}</span>
            </div>
          </div>
        </div>

        <!-- chart -->
        <vue-apex-charts
          id="revenue-report-chart"
          class=""
          type="bar"
          height="100%"
          :options="rippleChartData.options"
          :series="rippleChartData.data.values"
        />
      </b-col>

      <!-- <b-col
        md="4"
        class="budget-wrapper full-height"
      >
        <b-dropdown
          text="2023"
          size="sm"
          class="budget-dropdown"
          variant="outline-primary"
        >
          <b-dropdown-item
            v-for="year in data.years"
            :key="year"
          >
            {{ year }}
          </b-dropdown-item>
        </b-dropdown>

        <h2 class="mb-25">
          ${{ data.price }}
        </h2>
        <div class="d-flex justify-content-center">
          <span class="font-weight-bolder mr-25">Increase in<br>Avg. Value Per Customer</span>
        </div>

        <vue-apex-charts
          id="budget-chart"
          type="line"
          height="100"
          :options="budgetChart.options"
          :series="data.values"
        />

        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
        >
          Share
        </b-button>
      </b-col> -->
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol,
  // BDropdown, BDropdownItem,
  // BButton,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    VueApexCharts,
    // BDropdown,
    // BDropdownItem,
    BCard,
    // BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      revenue_report: {},
      // budget chart
      budgetChart: {
        options: {
          chart: {
            height: 80,
            toolbar: { show: false },
            zoom: { enabled: false },
            legend: {
              show: false,
            },
            type: 'line',
            sparkline: { enabled: true },
          //   hide captions legends
          },
          stroke: {
            curve: 'smooth',
            dashArray: [0, 5],
            width: [2],
          },
          colors: [$themeColors.primary, '#dcdae3'],
          tooltip: {
            enabled: false,
          },
        },
      },
    }
  },
  computed: {
    rippleChartData() {
      const rippleChartData = {}
      const chartOptions = {
        chart: {
          stacked: true,
          type: 'bar',
          toolbar: { show: false },
        },
        grid: {
          padding: {
            top: -20,
            bottom: -10,
          },
          yaxis: {
            lines: { show: false },
          },
        },
        xaxis: {
          categories: this.data.categories,
          labels: {
            style: {
              colors: '#6E6B7B',
              fontSize: '0.86rem',
              fontFamily: 'Montserrat',
            },
          },
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        colors: ['#28C76F', '#EA5455'],
        plotOptions: {
          bar: {
            columnWidth: '17%',
            endingShape: 'rounded',
          },
          distributed: true,
        },
        yaxis: {
          labels: {
            style: {
              colors: '#6E6B7B',
              fontSize: '0.86rem',
              fontFamily: 'Montserrat',
            },
          },
        },
      }

      rippleChartData.data = this.data
      rippleChartData.options = chartOptions
      return rippleChartData
    },
  },
}
</script>

<style lang="scss" scoped>
.revenue-report-wrapper {
  height: 80% !important;
  border-right-color: transparent !important;
}
</style>
