<template>
  <b-card
    v-if="data"
    class="piechart-card"
  >
    <b-row>
      <b-col cols="6">
        <b-card-title class="mb-1">
          Conversions Measured
        </b-card-title>
        <div class="font-small-2">
          By Status
        </div>
        <h3 class="mb-2">
          {{ total }} Total
        </h3>
        <b-card-text
          v-if="percentChange > 0"
          class="text-success"
        >
          <i class="fa-solid fa-chevron-up" />&nbsp;<span class="font-weight-bolder">{{ percentChange }}%</span>
        </b-card-text>
        <b-card-text
          v-else-if="percentChange < 0"
          class="text-danger"
        >
          <i class="fa-solid fa-chevron-down" />&nbsp;<span class="font-weight-bolder">{{ percentChange }}%</span>
        </b-card-text>
        <b-card-text
          v-else
          class="text-info"
        >
          <i class="fa-solid fa-equals" />&nbsp;<span class="font-weight-bolder">{{ percentChange }}%</span>
        </b-card-text>
      </b-col>
      <b-col cols="6">
        <!-- chart -->
        <vue-apex-charts
          v-if="pieChartData && pieChartData.data.length > 0"
          height="160"
          :options="pieChartData.options"
          :series="pieChartData.data"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BCardTitle, BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  name: 'PieChart',
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BCardText,
    VueApexCharts,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      total: 0,
      percentChange: 0,
      previousTotal: 0,
      statusColor: {
        verified: 'success',
        flagged: 'warning',
        spam: 'warning',
        new: 'info',
        rejected: 'danger',
        pending: 'warning',
        lost: 'danger',
        won: 'success',
      },
    }
  },
  computed: {
    pieChartData() {
      return this.formatChartData(this.data)
    },
  },
  methods: {
    calculatePercentage(value) {
      return String(parseInt(value, 10) / parseInt(this.total, 10))
    },
    formatChartData(chartData) {
      // console.log('formatChartData', chartData)

      const chartOptions = {
        chart: {
          type: 'donut',
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: { show: false },
        labels: [],
        colors: [],
        stroke: { width: 0 },
        grid: {
          padding: {
            right: -20,
            bottom: -8,
            left: -20,
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  offsetY: 15,
                },
                value: {
                  offsetY: -15,
                  formatter(val) {
                    // eslint-disable-next-line radix
                    return `${val}` // %
                  },
                },
                /* total: {
                  show: true,
                  offsetY: 15,
                  label: 'Conversions',
                  formatter() {
                    return 'Total'
                  },
                }, */
              },
            },
          },
        },
        responsive: [
          {
            breakpoint: 1325,
            options: {
              chart: {
                height: 100,
              },
            },
          },
          {
            breakpoint: 1200,
            options: {
              chart: {
                height: 120,
              },
            },
          },
          {
            breakpoint: 1045,
            options: {
              chart: {
                height: 100,
              },
            },
          },
          {
            breakpoint: 992,
            options: {
              chart: {
                height: 120,
              },
            },
          },
        ],
      }

      const pieChartData = []
      const chartLabels = []
      const chartColors = []

      if (chartData && chartData.period) {
        this.total = chartData.period.total || 0
        this.percentChange = chartData.period.percent_change || 0
        this.previousTotal = chartData.period.previous_total || 0

        chartData.statuses.forEach(status => {
          // console.log(JSON.stringify(status))
          pieChartData.push(status.count)
          chartLabels.push(status.name) // .toString().toUpperCase()
          chartColors.push($themeColors[this.statusColor[status.name]] || $themeColors.primary)
        })
      }

      chartOptions.labels = chartLabels
      chartOptions.colors = chartColors
      return { data: pieChartData, options: chartOptions }
    },
  },
}
</script>
