<template>
  <b-card
    no-body
    class="position-relative"
  >
    <b-card-body class="pb-0">
      <div class="truncate">
        <h2 class="mb-25 font-medium-1 font-weight-bolder">
          {{ statistic }}
        </h2>
        <span>{{ statisticTitle }}</span>
      </div>
    </b-card-body>

    <vue-apex-charts
      type="area"
      height="100"
      width="100%"
      :options="chartOptionsComputed"
      :series="chartData.data"
    />

    <!-- an absolute label to draw on right side of chart showing percent in red color -->
    <div class="absolute-right">
      <span
        id="label-red"
        class="font-weight-bolder text-danger"
      >{{ chartData.percent }}%</span>
    </div>
    <div class="absolute-left">
      <span
        id="label-white"
        class="font-weight-bolder text-large text-white"
      >{{ chartData.total }}</span>
    </div>

  </b-card>
</template>

<script>
import { BCard, BCardBody } from 'bootstrap-vue'
import { $themeColors } from '@themeConfig'
import VueApexCharts from 'vue-apexcharts'
// import { $themeColors } from '@themeConfig'
// import { areaChartOptions } from './chartOptions'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardBody,
  },
  props: {
    statistic: {
      type: String,
      required: true,
    },
    statisticTitle: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
    chartData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      chartOptions: {
        grid: {
          show: false,
          padding: {
            left: 0,
            right: 0,
          },
        },
        chart: {
          toolbar: {
            show: false,
          },
          sparkline: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
          width: 2.5,
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 0.9,
            opacityFrom: 0.5,
            opacityTo: 0.2,
            stops: [0, 80, 100],
          },
        },
        xaxis: {
          type: 'numeric',
          lines: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          labels: { show: false },
        },
        yaxis: [
          {
            y: 0,
            offsetX: 0,
            offsetY: 0,
            padding: {
              left: 0,
              right: 0,
            },
          },
        ],
        tooltip: {
          x: { show: false },
        },
        theme: {
          monochrome: {
            enabled: true,
            color: $themeColors[this.color],
            shadeTo: 'light',
            shadeIntensity: 0.65,
          },
        },
      },
    }
  },
  computed: {
    chartOptionsComputed() {
      return this.chartOptions
    },
  },
}
</script>

<style lang="scss" scoped>
#label-red{
  color: #EA5455;
}
#label-white{
  color: #8692D0;
}
.absolute-right {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 1rem;
}
.absolute-left {
  position: absolute;
  left: 0;
  bottom: 0;
  margin: 1rem;
}
</style>
