<template>
  <b-container
    id="dashboard"
  >
    <!-- header -->
    <b-row>
      <!-- header title -->
      <b-col md="6">
        <b-row
          align-v="center"
          class="align-items-stretch"
        >
          <b-col
            id="img-container"
            md="2"
            class="align-self-center"
          >
            <!-- <img
              id="img-dashboard"
              src="../../assets/images/svg/chart-infographic.svg"
              class="img-fluid"
              alt="Logo"
            > -->
            <font-awesome-icon
              :icon="['fas', 'chart-mixed']"
              size="2xl"
            />
          </b-col>
          <b-col
            md="10"
            class=""
          >
            <h3 class="font-weight-bold">
              Conversion Analytics
            </h3>
            <p class="mb-0">
              Clearly see your progress towards success
            </p>
          </b-col>
        </b-row>
      </b-col>

      <!-- date range select -->
      <b-col md="6">
        <b-row
          class="mt-1"
        >
          <b-col
            class="mb-1"
            md="6"
          >
            <b-form-select
              v-model="selectedDays"
              :options="daySelectOptions"
              @change="setSelectedDays"
            />
          </b-col>
          <b-col
            class="mb-1"
            md="6"
          >
            <flat-pickr
              v-model="dateRange"
              class="form-control position-relative"
              :config="{ mode: 'range' }"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- widgets -->
    <b-row class="mt-4 align-items-stretch">
      <b-col md="5">
        <pie-chart
          v-if="pieChartData"
          :data="pieChartData"
        />
        <b-row>
          <b-col class="mb-2">
            <radial-chart
              v-if="verifiedChartData"
              :data="verifiedChartData"
            />
          </b-col>
          <b-col class="mb-2">
            <radial-chart
              v-if="rejectedChartData"
              :data="rejectedChartData"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col
        md="7"
        class="mb-2"
      >
        <year-bar-chart
          v-if="conversionOverTimeChartData"
          :data="conversionOverTimeChartData"
        />
      </b-col>
    </b-row>
    <b-row class="justify-content-md-center">
      <b-col
        sm="6"
        md="3"
      >
        <line-chart
          v-if="verifiedLineChartData"
          statistic="Verified Conversions"
          statistic-title="Total"
          :color="statusColor.verified"
          :chart-data="verifiedLineChartData"
        />
      </b-col>
      <b-col
        sm="6"
        md="3"
      >
        <line-chart
          v-if="verifiedWonLineChartData"
          statistic="Verified Won"
          statistic-title="Total"
          :color="statusColor.won"
          :chart-data="verifiedWonLineChartData"
        />
      </b-col>
      <b-col
        sm="6"
        md="3"
      >
        <line-chart
          v-if="verifiedPendingLineChartData"
          statistic="Verified Pending"
          statistic-title="Total"
          :color="statusColor.pending"
          :chart-data="verifiedPendingLineChartData"
        />
      </b-col>
      <b-col
        sm="6"
        md="3"
      >
        <line-chart
          v-if="verifiedLostLineChartData"
          statistic="Verified Lost"
          statistic-title="Total"
          :color="statusColor.lost"
          :chart-data="verifiedLostLineChartData"
        />
      </b-col>
      <b-col
        sm="6"
        md="3"
      >
        <line-chart
          v-if="rejectedConversionLineChartData"
          statistic="Rejected Conversions"
          statistic-title="Total"
          :color="statusColor.rejected"
          :chart-data="rejectedConversionLineChartData"
        />
      </b-col>
      <b-col
        sm="6"
        md="3"
      >
        <line-chart
          v-if="spamConversionLineChartData"
          statistic="Spam Conversions"
          statistic-title="Total"
          :color="statusColor.spam"
          :chart-data="spamConversionLineChartData"
        />
      </b-col>
    </b-row>
    <!-- <b-row>
      <b-col
        md="12"
        class="mb-2"
      >
        <bar-chart
          v-if="weekPerformanceChartData"
          :chart-data="weekPerformanceChartData"
        />
      </b-col>
    </b-row> -->
  </b-container>
</template>

<script>
import {
  BCol, BContainer, BRow, BFormSelect,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import useJwt from '@/auth/jwt/useJwt'
import PieChart from '@/views/components/charts/pieChart.vue'
import RadialChart from '@/views/components/charts/radialChart.vue'
import YearBarChart from '@/views/components/charts/yearBarChart.vue'
import LineChart from '@/views/components/charts/lineChart.vue'
// import BarChart from '@/views/components/charts/barChart.vue'

const getDaysInMonth = (year, month) => new Date(year, month, 0).getDate()
const addMonths = (input, months) => {
  const date = new Date(input)
  date.setDate(1)
  date.setMonth(date.getMonth() + months)
  date.setDate(Math.min(input.getDate(), getDaysInMonth(date.getFullYear(), date.getMonth() + 1)))
  return date
}

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BFormSelect,
    PieChart,
    RadialChart,
    YearBarChart,
    LineChart,
    // BarChart,

    // date picker
    flatPickr,
  },
  data() {
    return {
      loadingWidgets: true,
      dateRange: null,
      selectedDays: 7,
      daySelectOptions: [
        { value: null, text: '' },
        { value: 7, text: 'Last 7 days' },
        { value: 14, text: 'Last 14 days' },
        { value: 30, text: 'Last 30 days' },
        { value: 60, text: 'Last 60 days' },
        { value: 90, text: 'Last 90 days' },
      ],

      conversionOverTimeChartData: {
        categories: [],
      },
      pieChartData: {},
      verifiedChartData: {},
      rejectedChartData: {},
      verifiedLineChartData: {},
      verifiedWonLineChartData: {},
      verifiedPendingLineChartData: {},
      verifiedLostLineChartData: {},
      rejectedConversionLineChartData: {},
      spamConversionLineChartData: {},
      weekPerformanceChartData: {},

      statusColor: {
        verified: 'success',
        flagged: 'warning',
        spam: 'warning',
        new: 'info',
        rejected: 'danger',
        pending: 'warning',
        lost: 'danger',
        won: 'success',
      },
    }
  },
  computed: {
    userData() {
      return this.$store.getters['users/getCurrentUser']
    },
    userDomain() {
      return this.userData && this.userData.domains ? this.userData.domains[0].domain : null
    },
    startDate() {
      const splitRange = this.dateRange.split(' ')
      const startDate = splitRange[0] || ''
      return startDate
    },
    endDate() {
      const splitRange = this.dateRange.split(' ')
      const endDate = splitRange[2] || splitRange[0] || ''
      return endDate
    },
  },
  watch: {
    dateRange(value) {
      this.setDateDropdown(value)
    },
  },
  created() {
    // set date range for past 7 days
    this.setSelectedDays(7)
  },
  methods: {
    reloadWidgets() {
      this.getPieChartData()
      this.getVerifiedBarChartData()
      this.getRejectedBarChartData()
      this.getVerifiedLineChartData()
      this.getWonLineChartData()
      this.gePendingLineChartData()
      this.getLostLineChartData()
      this.getRejectedLineChartData()
      this.getSpamLineChartData()
      if (this.conversionOverTimeChartData.categories.length === 0) {
        this.getYearBarChartData()
      }
    },
    getPieChartData() {
      if (this.userDomain) {
        useJwt.getConversionsStatusChartData(this.userDomain, this.startDate, this.endDate)
          .then(response => {
            const responseData = response.data.data
            console.log('getConversionsStatusChartData', responseData)
            this.pieChartData = responseData
          })
          .catch(error => {
            console.log('getPieChartData ERROR', error.response)
          })
      }
    },
    getVerifiedBarChartData() {
      if (this.userDomain) {
        useJwt.getConversionsStatusBarChartData('verified', this.userDomain, this.startDate, this.endDate)
          .then(response => {
            const [responseData] = response.data.data
            console.log('getConversionsStatusBarChartData verified', responseData)
            const verifiedBarChartData = {
              title: 'Verified',
              percent: 0,
              values: [],
              labels: ['Verified'],
              color: '#28C76F',
              footer: true,
            }
            verifiedBarChartData.percent = parseInt(responseData.period_status_percent_change, 10) || 0
            verifiedBarChartData.values.push(parseInt(responseData.status_percent, 10) || 0)
            this.verifiedChartData = verifiedBarChartData
          })
          .catch(error => {
            console.log('getVerifiedBarChartData ERROR', error.response)
          })
      }
    },
    getRejectedBarChartData() {
      if (this.userDomain) {
        useJwt.getConversionsStatusBarChartData('rejected', this.userDomain, this.startDate, this.endDate)
          .then(response => {
            const [responseData] = response.data.data
            console.log('getConversionsStatusBarChartData rejected', responseData)
            const rejectedChartData = {
              title: 'Rejected',
              percent: 0,
              values: [],
              labels: ['Rejected'],
              color: '#EA5455',
              footer: false,
            }
            rejectedChartData.values.push(parseInt(responseData.status_percent, 10) || 0)
            this.rejectedChartData = rejectedChartData
          })
          .catch(error => {
            console.log('getRejectedBarChartData ERROR', error.response)
          })
      }
    },
    getVerifiedLineChartData() {
      if (this.userDomain) {
        useJwt.getConversionsStatusLineChartData('verified', this.userDomain, this.startDate, this.endDate)
          .then(response => {
            const responseData = response.data.data
            console.log('getConversionsStatusLineChartData verified', responseData)
            const chartData = {
              data: [
                {
                  data: [],
                  name: 'Verified',
                },
              ],
              percent: 0,
              total: 0,
            }
            chartData.percent = responseData.period_change_percent || 0
            chartData.total = responseData.status_total || 0
            responseData.chart.forEach(data => {
              chartData.data[0].data.push(data.total)
            })
            this.verifiedLineChartData = chartData
          })
          .catch(error => {
            console.log('getVerifiedLineChartData ERROR', error)
          })
      }
    },
    getWonLineChartData() {
      if (this.userDomain) {
        useJwt.getConversionsStatusLineChartData('won', this.userDomain, this.startDate, this.endDate)
          .then(response => {
            const responseData = response.data.data
            console.log('getConversionsStatusLineChartData won', responseData)
            const chartData = {
              data: [
                {
                  data: [],
                  name: 'Won',
                },
              ],
              percent: 0,
              total: 0,
            }
            chartData.percent = responseData.period_change_percent || 0
            chartData.total = responseData.status_total || 0
            responseData.chart.forEach(data => {
              chartData.data[0].data.push(data.total)
            })
            this.verifiedWonLineChartData = chartData
          })
          .catch(error => {
            console.log('getWonLineChartData ERROR', error)
          })
      }
    },
    gePendingLineChartData() {
      if (this.userDomain) {
        useJwt.getConversionsStatusLineChartData('pending', this.userDomain, this.startDate, this.endDate)
          .then(response => {
            const responseData = response.data.data
            console.log('getConversionsStatusLineChartData pending', responseData)
            const chartData = {
              data: [
                {
                  data: [],
                  name: 'Pending',
                },
              ],
              percent: 0,
              total: 0,
            }
            chartData.percent = responseData.period_change_percent || 0
            chartData.total = responseData.status_total || 0
            responseData.chart.forEach(data => {
              chartData.data[0].data.push(data.total)
            })
            this.verifiedPendingLineChartData = chartData
          })
          .catch(error => {
            console.log('gePendingLineChartData ERROR', error)
          })
      }
    },
    getLostLineChartData() {
      if (this.userDomain) {
        useJwt.getConversionsStatusLineChartData('lost', this.userDomain, this.startDate, this.endDate)
          .then(response => {
            const responseData = response.data.data
            console.log('getConversionsStatusLineChartData lost', responseData)
            const chartData = {
              data: [
                {
                  data: [],
                  name: 'Lost',
                },
              ],
              percent: 0,
              total: 0,
            }
            chartData.percent = responseData.period_change_percent || 0
            chartData.total = responseData.status_total || 0
            responseData.chart.forEach(data => {
              chartData.data[0].data.push(data.total)
            })
            this.verifiedLostLineChartData = chartData
          })
          .catch(error => {
            console.log('getLostLineChartData ERROR', error)
          })
      }
    },
    getRejectedLineChartData() {
      if (this.userDomain) {
        useJwt.getConversionsStatusLineChartData('rejected', this.userDomain, this.startDate, this.endDate)
          .then(response => {
            const responseData = response.data.data
            console.log('getConversionsStatusLineChartData rejected', responseData)
            const chartData = {
              data: [
                {
                  data: [],
                  name: 'Rejected',
                },
              ],
              percent: 0,
              total: 0,
            }
            chartData.percent = responseData.period_change_percent || 0
            chartData.total = responseData.status_total || 0
            responseData.chart.forEach(data => {
              chartData.data[0].data.push(data.total)
            })
            this.rejectedConversionLineChartData = chartData
          })
          .catch(error => {
            console.log('getRejectedLineChartData ERROR', error)
          })
      }
    },
    getSpamLineChartData() {
      if (this.userDomain) {
        useJwt.getConversionsStatusLineChartData('spam', this.userDomain, this.startDate, this.endDate)
          .then(response => {
            const responseData = response.data.data
            console.log('getConversionsStatusLineChartData spam', responseData)
            const chartData = {
              data: [
                {
                  data: [],
                  name: 'Spam',
                },
              ],
              percent: 0,
              total: 0,
            }
            chartData.percent = responseData.period_change_percent || 0
            chartData.total = responseData.status_total || 0
            responseData.chart.forEach(data => {
              chartData.data[0].data.push(data.total)
            })
            this.spamConversionLineChartData = chartData
          })
          .catch(error => {
            console.log('getSpamLineChartData ERROR', error)
          })
      }
    },
    getYearBarChartData() {
      const currentDate = new Date()

      // Get the first day of the current month
      // const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)

      const halfYearAgo = addMonths(currentDate, -10) // ten months ago

      // Format the dates in MySQL format (YYYY-MM-DD)
      const startDate = halfYearAgo.toISOString().slice(0, 10)
      const endDate = currentDate.toISOString().slice(0, 10)

      if (this.userDomain) {
        useJwt.getConversionsBarChartData(this.userDomain, startDate, endDate)
          .then(response => {
            const responseData = response.data.data
            console.log('getConversionsBarChartData', responseData)
            const chartData = {
              title: 'Conversions Over Time',
              positive: 'Verified',
              negative: 'Rejected',
              values: [
                {
                  name: 'Verified',
                  data: [],
                },
                {
                  name: 'Rejected',
                  data: [],
                },
              ],
              categories: [],
              footer: false,
            }

            const verifiedSeries = {
              name: 'Verified',
              data: [],
            }
            const rejectedSeries = {
              name: 'Rejected',
              data: [],
            }

            responseData.forEach(data => {
              chartData.categories.push(data.date)
              verifiedSeries.data.push(data.verified)
              rejectedSeries.data.push(data.rejected)
            })
            chartData.values = [verifiedSeries, rejectedSeries]
            this.conversionOverTimeChartData = chartData
          })
          .catch(error => {
            console.log('getYearBarChartData ERROR', error.response)
          })
      }
    },

    /* SELECTORS */

    setSelectedDays(numDays) {
      // set date range when day dropdown is changed
      if (numDays) {
        const dayRange = parseInt(numDays, 10) - 1
        const currentDate = new Date().toJSON().slice(0, 10)
        const previousDate = new Date(Date.now() - dayRange * 24 * 60 * 60 * 1000).toJSON().slice(0, 10)
        this.dateRange = `${previousDate} to ${currentDate}`
        this.selectedDays = parseInt(numDays, 10)
      }
    },
    setDateDropdown() {
      // set day dropdown when date range is changed
      const timeDifference = new Date(this.startDate).getTime() - new Date(this.endDate).getTime()
      const dayDifference = Math.abs(timeDifference / (1000 * 3600 * 24)) + 1
      this.selectedDays = parseInt(dayDifference, 10)

      setTimeout(() => {
        if (this.userData) {
          this.reloadWidgets() // refresh all widgets when date range changed
        }
      }, 1000)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
#img-container{
  text-align: end;
}
#img-dashboard{
  background: rgba(127, 43, 218, 0.16);
}
.custom-select {
  border-radius: 6px !important;
  border: 1px solid #7F2BDA !important;
  color: #7F2BDA !important;
}

/* Style the caret (arrow) inside the select */
.custom-select::after {
  border-color: #7F2BDA !important;
}
.flatpickr-input{
  background-color: #7F2BDA !important;
  color: #fff !important;
  border: 1px solid #7F2BDA !important;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23b4b7bd' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-position: calc(100% - 12px) 13px, calc(100% - 20px) 13px, 100% 0;
  background-size: 18px 14px, 18px 14px;
  background-repeat: no-repeat;
}
/*add a caret to the input*/
.flatpickr-input:after {
  content: "\25BC"; /* Unicode character for down-arrow */
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #fff;
}
</style>
